import useBootstrapData from '../core/use-bootstrap-data';
import {useChangeLocale} from './change-locale';

export function useSelectedLocale() {
  const i18n = useBootstrapData().i18n;
  const changeLocale = useChangeLocale();
  return {
    locale: i18n,
    localeCode: i18n?.language || 'en',
    lines: i18n?.lines,
    changeLocale,
  };
}
