import {useMutation} from '@tanstack/react-query';
import {BackendResponse} from '../http/backend-response/backend-response';
import {mergeBootstrapData} from '../core/use-bootstrap-data';
import {Localization} from './localization';
import {apiClient} from '../http/query-client';
import {showHttpErrorToast} from '../utils/http/show-http-error-toast';

interface Response extends BackendResponse {
  locale: Localization;
}

interface Props {
  locale?: string;
}

function changeLocale(props: Props): Promise<Response> {
  return apiClient.post(`users/me/locale`, props).then(r => r.data);
}

export function useChangeLocale() {
  return useMutation((props: Props) => changeLocale(props), {
    onSuccess: response => {
      mergeBootstrapData({
        i18n: response.locale,
        language: response.locale.language,
      });
    },
    onError: err => showHttpErrorToast(err),
  });
}
